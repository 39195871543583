// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-tsx": () => import("/home/jenkins/workspace/NW/web/prod/microsoftteams/src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-connected-tsx": () => import("/home/jenkins/workspace/NW/web/prod/microsoftteams/src/pages/connected.tsx" /* webpackChunkName: "component---src-pages-connected-tsx" */),
  "component---src-pages-error-tsx": () => import("/home/jenkins/workspace/NW/web/prod/microsoftteams/src/pages/error.tsx" /* webpackChunkName: "component---src-pages-error-tsx" */),
  "component---src-pages-index-tsx": () => import("/home/jenkins/workspace/NW/web/prod/microsoftteams/src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-install-tsx": () => import("/home/jenkins/workspace/NW/web/prod/microsoftteams/src/pages/install.tsx" /* webpackChunkName: "component---src-pages-install-tsx" */),
  "component---src-pages-installguide-tsx": () => import("/home/jenkins/workspace/NW/web/prod/microsoftteams/src/pages/installguide.tsx" /* webpackChunkName: "component---src-pages-installguide-tsx" */),
  "component---src-pages-microsoft-oauth-success-tsx": () => import("/home/jenkins/workspace/NW/web/prod/microsoftteams/src/pages/microsoftOauthSuccess.tsx" /* webpackChunkName: "component---src-pages-microsoft-oauth-success-tsx" */),
  "component---src-pages-post-license-tsx": () => import("/home/jenkins/workspace/NW/web/prod/microsoftteams/src/pages/post-license.tsx" /* webpackChunkName: "component---src-pages-post-license-tsx" */),
  "component---src-pages-success-tsx": () => import("/home/jenkins/workspace/NW/web/prod/microsoftteams/src/pages/success.tsx" /* webpackChunkName: "component---src-pages-success-tsx" */)
}

exports.data = () => import("/home/jenkins/workspace/NW/web/prod/microsoftteams/.cache/data.json")

